.container-yellow {
    background-color: #0c5554;
    width: 100%;
    min-height: 400px;
    justify-content: center;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
}

iframe {
    justify-content: center;
    filter: brightness(90%);
    width: 40%;
    height: 400px;
    border-radius: 2rem;
    box-shadow: 0 0 20px #07dfdc;
    margin: 0px 19px;
}

.inline-block {
    font-size: 15px;
    text-align: left;
    color: #ffffff;
    margin: 1.5rem 0px;
}

@media screen and (max-width: 1100px) {
    .container-yellow {
        background-color: #0c5554;
        width: 100%;
        min-height: 400px;
        justify-content: center;
        padding: 0rem 2rem;
        display: inline-block;
        align-items: center;
    }
    iframe {
        justify-content: center;
        filter: brightness(90%);
        width: 40%;
        height: 400px;
        border-radius: 2rem;
        box-shadow: 0 0 20px #07dfdc;
        margin: 0 auto;
    }
    .inline-block {
        font-size: 15px;
        text-align: left;
        color: hsl(0, 100%, 36%);
    }
}

@media screen and (max-width: 1100px) {
    .container-yellow {
        background-color: #0c5554;
        width: 100%;
        min-height: 400px;
        justify-content: center;
        padding: 4rem 2rem;
        display: inline-block;
        align-items: center;
    }
    iframe {
        justify-content: center;
        filter: brightness(90%);
        width: 70%;
        height: 400px;
        border-radius: 2rem;
        box-shadow: 0 0 20px #07dfdc;
        margin: 0 auto;
    }
    .inline-block {
        font-size: 15px;
        text-align: left;
        color: #ffffff;
    }
}

@media screen and (max-width: 800px) {
    .container-yellow {
        background-color: #0c5554;
        width: 100%;
        min-height: 400px;
        justify-content: center;
        padding: 4rem 2rem;
        display: inline-block;
        align-items: center;
    }
    iframe {
        justify-content: center;
        filter: brightness(90%);
        width: 80%;
        height: 400px;
        border-radius: 2rem;
        box-shadow: 0 0 20px #07dfdc;
        margin: 0 auto;
    }
    .inline-block {
        font-size: 15px;
        text-align: left;
        color: #ffffff;
    }
}

@media screen and (max-width: 668px) {
    .container-yellow {
        background-color: #0c5554;
        width: 100%;
        min-height: 400px;
        justify-content: center;
        padding: 4rem 2rem;
        display: inline-block;
        align-items: center;
    }
    .inline-block {
        font-size: 15px;
        text-align: left;
    }
    iframe {
        justify-content: center;
        filter: brightness(90%);
        width: 85%;
        height: 400px;
        border-radius: 2rem;
        box-shadow: 0 0 20px #07dfdc;
        margin: 0 auto;
    }
}