.container-contact {
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5)50%, rgba(0, 0, 0, 0.4)50%), url(/public/images/pexels-chepté-cormani-1416530.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

#font {
    color: white;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
}

#paragraph {
    color: #fff;
    text-align: left;
    font-size: 17px;
    text-transform: capitalize;
}

.container-width {
    width: 50%;
    margin: 0 auto;
}

form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    width: 80%;
    font-size: 16px;
}

input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
    }
}

textarea {
    max-width: 50%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
    }
}

label {
    margin-top: 1rem;
    color: #fff;
}

input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    background: transparent;
    color: white;
    border: none;
}

input[type="submit"]:hover {
    background: rgba(99, 206, 255, 0.438);
}

#btn {
    background: transparent;
    color: white;
    font-size: 20px;
    padding: 10px;
    margin: 8px;
    transition-delay: 2ms;
}

#btn:hover {
    background-color: #0c5554;
}

.pop-up {
    background: #0c5554;
    width: 600px;
    margin: 0 auto;
    color: #fff;
    padding: 12px;
    margin-top: 12vh;
}

#btn-2 {
    background: transparent;
    color: white;
    font-size: 20px;
    padding: 10px;
    margin: 8px;
    transition-delay: 2ms;
    justify-content: center;
}

#btn-2:hover {
    background-color: #ffffff;
    color: black;
}

@media screen and (max-width: 1100px) {

    .container-contact {
        width: 100%;
        height: 500px;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5)50%, rgba(0, 0, 0, 0.4)50%), url(/public/images/pexels-chepté-cormani-1416530.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top:10vh;
    }
    form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        font-size: 16px;
    }
    #paragraph {
        color: #fff;
        text-align: left;
        font-size: 12px;
        text-transform: capitalize;
    }
}

@media screen and (max-width: 800px) {
    form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        font-size: 16px;
    }
    #paragraph {
        color: #fff;
        text-align: left;
        font-size: 12px;
        text-transform: capitalize;
    }
}

@media screen and (max-width: 668px) {
    form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 auto;
        justify-content: flex-start;
        width: 120%;
        font-size: 16px;
    }
    #paragraph {
        color: #fff;
        text-align: left;
        font-size: 12px;
        text-transform: capitalize;
    }
}