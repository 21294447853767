* {
    padding: 0;
    margin: 0;
}

.navbar {
    height: 65vh;
    min-width: 60%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: black;
    min-width: 100%;
    opacity: 50%;
    position: fixed;
    top: 0;
    z-index: 3;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Brush Script MT', cursive;
    font-size: 3rem;
    align-items: center;
    width: 89px;
    height: 89px;
    z-index: 5;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: flex-end;
}

.nav-item {
    height: 80px;
    width: 150px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: tahoma;
    margin-top: 3vh;
}

.nav-links:hover {
    border-bottom: 4px solid rgb(255, 255, 255);
    transition: all 0.2s ease-out;
    width: fit-content;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        height: 50vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: black;
        left: 0;
        opacity: 70;
        width: 100%;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-menu:hover {
        box-shadow: 2px 4px rgb(255, 255, 255)
    }
    .nav-links {
        text-align: center;
        padding: 17px;
        width: 100%;
        margin-top: -10px;
        border-bottom: none;
        color: rgb(255, 255, 255);
    }
    .nav-links:hover {
        border-bottom: 4px solid rgb(255, 255, 255);
        transition: all 0.2s ease-out;
        width: fit-content;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
    .fa-bars {
        color: #fff;
        font-size: 2rem;
    }
}